<template>
  <div class="py-4">
    <div class="border border-gray-300 rounded-sm shadow mb-6">
      <div class="border-b border-gray-300 p-6 flex justify-between">
        <div class="text-xl font-semibold">
          Account
        </div>
        <svg
          v-if="flagFalse"
          @click.prevent="refresh"
          id="refresh-button"
          class="w-8 h-8 text-red-700"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
      </div>

      <div class="border-b border-gray-300 p-6">
        <!-- <div>Billing Id: {{ billing.id }}</div> -->
        <div class="font-semibold mb-3">Billing Address:</div>
        <div v-if="loadingBilling" class="flex justify-center gap-4 mb-3">
          <div class="spinner dark"></div>
          <div>Loading . . .</div>
        </div>
        <form
          v-if="!loadingBilling"
          id="billing-address"
          @submit.prevent="updateBillingInfo"
        >
          <div class="w-full flex border rounded mb-5">
            <label for="billing_email" class="p-2.5 border-r"
              >Email Address</label
            >
            <div class="flex-grow">
              <input
                type="text"
                name="billing_email"
                class="w-full rounded-r px-5 py-2.5"
                v-model="billing.email"
              />
            </div>
          </div>
          <div class="w-full flex border rounded mb-5">
            <label for="billing_name" class="p-2.5 border-r">Company</label>
            <div class="flex-grow">
              <input
                type="text"
                name="billing_name"
                class="w-full rounded-r px-5 py-2.5"
                v-model="billing.name"
              />
            </div>
          </div>
          <div class="flex gap-4">
            <div class="w-full">
              <div class="w-full flex border rounded mb-5">
                <label for="billing_add1" class="p-2.5 border-r"
                  >Address 1</label
                >
                <div class="flex-grow">
                  <input
                    type="text"
                    name="billing_add1"
                    class="w-full rounded-r px-5 py-2.5"
                    v-model="billing.address.line1"
                  />
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="w-full flex border rounded mb-5">
                <label for="billing_add2" class="p-2.5 border-r"
                  >Address 2</label
                >
                <div class="flex-grow">
                  <input
                    type="text"
                    name="billing_add2"
                    class="w-full rounded-r px-5 py-2.5"
                    v-model="billing.address.line2"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex gap-4">
            <div class="w-full">
              <div class="w-full flex border rounded mb-5">
                <label for="billing_city" class="p-2.5 border-r">City</label>
                <div class="flex-grow">
                  <input
                    type="text"
                    name="billing_city"
                    class="w-full rounded-r px-5 py-2.5"
                    v-model="billing.address.city"
                  />
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="w-full flex border rounded mb-5">
                <label for="billing_country" class="p-2.5 border-r"
                  >Country</label
                >
                <div class="flex-grow">
                  <input
                    type="text"
                    name="billing_country"
                    class="w-full rounded-r px-5 py-2.5"
                    v-model="billing.address.country"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex gap-4">
            <div class="w-full">
              <div class="w-full flex border rounded mb-5">
                <label for="billing_state" class="p-2.5 border-r">State</label>
                <div class="flex-grow">
                  <input
                    type="text"
                    name="billing_state"
                    class="w-full rounded-r px-5 py-2.5"
                    v-model="billing.address.state"
                  />
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="w-full flex border rounded mb-5">
                <label for="billing_zip" class="p-2.5 border-r">Zip Code</label>
                <div class="flex-grow">
                  <input
                    type="text"
                    name="billing_zip"
                    class="w-full rounded-r px-5 py-2.5"
                    v-model="billing.address.postal_code"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-full flex justify-end mb-4">
            <button
              type="submit"
              id="update-billing-info"
              class="btn px-12 py-4 text-white font-bold bg-red-600 rounded flex gap-2 justify-center"
            >
              <div class="spinner hidden"></div>
              <div class="content">Save</div>
            </button>
          </div>
        </form>
      </div>
      <div class="border-b border-gray-300 p-6">
        <!-- <div>Billing Source Id: {{ billing.default_source }}</div> -->
        <div class="font-semibold mb-3">Credit Card:</div>
        <div v-if="loadingCard" class="flex justify-center gap-4 mb-3">
          <div class="spinner dark"></div>
          <div>Loading . . .</div>
        </div>
        <div
          v-if="!loadingCard && creditcard.last4"
          @click="ccFormExpanded = !ccFormExpanded"
          class="flex gap-2 items-center text-lg text-gray-400 border rounded px-5 py-2 mb-3"
        >
          <svg
            class="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
            <path
              fill-rule="evenodd"
              d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
              clip-rule="evenodd"
            />
          </svg>
          <div>
            {{ creditcard.brand }} ... {{ creditcard.last4 }} exp.
            {{ ("00" + creditcard.exp_month).slice(-2) }}/{{
              ("00" + creditcard.exp_year).slice(-2)
            }}
          </div>
          <div class="px-4">{{ creditcard.name }}</div>
          <div class="flex-1"></div>
          <svg
            v-if="!ccFormExpanded"
            class="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
          <svg
            v-if="ccFormExpanded"
            class="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 15l7-7 7 7"
            />
          </svg>
        </div>
        <div :class="!loadingCard && ccFormExpanded ? '' : 'slideup'">
          <form id="creditcard-form" class="my-5">
            <div class="w-full flex border rounded mb-5">
              <label for="cardholder_name" class="p-2.5 border-r"
                >Cardholder</label
              >
              <div class="flex-grow">
                <input
                  type="text"
                  name="cardholder_name"
                  class="w-full rounded-r px-5 py-2.5"
                  v-model="creditcard.name"
                />
              </div>
            </div>
            <div class="w-full flex border rounded mb-5">
              <label for="card-element" class="p-2.5 border-r"
                >Credit Card</label
              >
              <div class="flex-grow bg-white rounded-r">
                <div id="card-element" class="w-full px-5 py-3">
                  <!-- A Stripe Element will be inserted here. -->
                </div>
              </div>
            </div>

            <div class="w-full flex justify-end mb-4">
              <button
                v-if="creditcard.last4"
                @click.prevent="deleteCreditCard()"
                type="button"
                class="btn min-w-1/4 px-12 py-4 text-red-600 rounded flex gap-2 justify-center"
              >
                <div class="content underline">Delete Card</div>
              </button>
              <button
                type="submit"
                id="update-credit-card"
                class="btn min-w-1/4 px-12 py-4 text-white font-bold bg-red-600 rounded flex gap-2 justify-center"
              >
                <div class="spinner hidden"></div>
                <div class="content">
                  <span>{{
                    creditcard.last4.length > 0 ? "Update" : "Add"
                  }}</span>
                  Credit card
                </div>
              </button>
            </div>
          </form>
          <!-- Used to display form errors. -->
          <div
            id="card-element-errors"
            role="alert"
            class="text-sm text-red-600 "
          ></div>
        </div>
      </div>

      <div
        v-if="creditcard.last4 || Object.keys(selectedPlan).length"
        class="border-b border-gray-300 p-6"
      >
        <div class="font-semibold mb-3">Subscription Plan:</div>
        <div v-if="loadingPlan" class="flex justify-center gap-4 mb-3">
          <div class="spinner dark"></div>
          <div>Loading . . .</div>
        </div>
        <div
          @click="toggleSubForm()"
          v-if="!loadingPlan && Object.keys(selectedPlan).length"
          class="flex gap-2 items-center text-lg text-gray-400 border rounded px-5 py-2 mb-3"
        >
          <div>
            <span class="font-semibold">
              {{ selectedPlan.name }} - {{ selectedPlan.price_string }}
            </span>
            <p>
              Current period:
              {{ convertDate(selectedPlan.current_period_start) }} -
              {{ convertDate(selectedPlan.current_period_end) }}
            </p>
            <p
              v-if="
                selectedPlan.collection_method == 'charge_automatically' &&
                  !selectedPlan.cancel_at_period_end &&
                  !selectedPlan.cancel_at &&
                  !selectedPlan.canceled_at
              "
            >
              Renews at end of month
            </p>
            <p
              v-if="
                selectedPlan.cancel_period_end ||
                  (selectedPlan.cancel_at && selectedPlan.canceled_at)
              "
              class="text-red-500"
            >
              Cancelled, ends on {{ convertDate(selectedPlan.cancel_at) }}
            </p>
          </div>
          <div class="flex-1"></div>
          <div
            v-if="
              !selectedPlan.cancel_period_end &&
                !selectedPlan.cancel_at &&
                !selectedPlan.canceled_at
            "
          >
            <svg
              v-if="!subFormExpanded && plans.length"
              class="w-8 h-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
            <svg
              v-if="subFormExpanded && plans.length"
              class="w-8 h-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 15l7-7 7 7"
              />
            </svg>
          </div>
        </div>

        <div
          v-if="!loadingPlan"
          :class="
            subFormExpanded || Object.keys(selectedPlan).length == 0
              ? ''
              : 'slideup'
          "
        >
          <form id="subscription-form" class="my-5">
            <div v-if="flagFalse && plans.length > 1">
              <div
                v-for="(plan, index) in plans"
                :key="index"
                class="w-full flex gap-3 border rounded mb-5 px-5 py-2.5 bg-white"
              >
                <input
                  type="radio"
                  name="subscription_group"
                  :id="plan.price_id"
                  :value="plan.price_id"
                  class="mt-1.5"
                  v-model="pickedPlan"
                />
                <label :for="plan.price_id" class="w-full flex flex-col">
                  <span class="font-semibold">
                    {{ plan.name }} - {{ plan.price_string }}
                  </span>
                  <p class="">{{ plan.description }}</p>
                </label>
              </div>
            </div>

            <div class="w-full flex justify-end mb-4 gap-4">
              <template v-if="Object.keys(selectedPlan).length">
                <button
                  v-if="
                    !selectedPlan.cancel_period_end &&
                      !selectedPlan.cancel_at &&
                      !selectedPlan.canceled_at
                  "
                  @click.prevent="deleteSubscription()"
                  type="button"
                  id="delete-subscription"
                  class="btn min-w-1/4 px-12 py-4 text-white font-bold bg-red-600 rounded flex gap-2 justify-center"
                >
                  <div class="spinner hidden"></div>
                  <div class="content">Delete Subscription</div>
                </button>

                <button
                  v-if="plans.length > 1"
                  type="button"
                  id="update-subscription"
                  class="btn min-w-1/4 px-12 py-4 text-white font-bold bg-red-600 rounded flex gap-2 justify-center"
                >
                  <div class="spinner hidden"></div>
                  <div class="content">Update Subscription</div>
                </button>
              </template>

              <button
                v-else
                @click="addSubscription()"
                type="button"
                id="add-subscription"
                class="btn min-w-1/4 px-12 py-4 text-white font-bold bg-red-600 rounded flex gap-2 justify-center"
              >
                <div class="spinner hidden"></div>
                <div class="content">Add Subscription</div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const firebase = require("../firebaseConfig");
import { loadStripe } from "@stripe/stripe-js";

export default {
  name: "BillingAccountComponent",
  data: () => ({
    user: {},
    business: {},
    billing: {
      id: "",
      email: "",
      name: "",
      address: {
        city: "",
        country: "",
        line1: "",
        line2: "",
        postal_code: "",
        state: "",
      },
      default_source: "",
      subscriptions: {
        data: [],
      },
    },
    creditcard: {
      name: "",
      last4: "",
    },
    stripe: null,
    elements: null,
    ccFormExpanded: false,
    subFormExpanded: false,
    plans: [],
    selectedPlan: {},
    pickedPlan: "",
    loadingPlan: true,
    loadingCard: true,
    submittedCard: false,
    loadingBilling: true,
    flagFalse: false,
  }),
  mounted() {
    this.getUser();
    this.createStripe();
  },
  methods: {
    toggleSubForm() {
      if (this.plans.length) {
        this.subFormExpanded = !this.subFormExpanded;
      }
    },
    async refresh() {
      const refreshBtn = document.getElementById("refresh-button");
      refreshBtn.classList.add("refresh");
      const refresh = firebase.functions.httpsCallable("refreshCustomer");
      refresh({
        id: this.billing.id,
        business_id: this.user.business_id,
        billing_id: this.user.business_id,
      }).then(() => {
        refreshBtn.classList.remove("refresh");
      });
    },
    async deleteSubscription() {
      var btnspinner = document.querySelector("#delete-subscription .spinner");
      btnspinner.classList.toggle("hidden");
      const deleteSub = firebase.functions.httpsCallable("cancelSubscription");
      deleteSub({
        subscription_id: this.selectedPlan.id,
      }).then((result) => {
        if (result.data) {
          // console.log("deleted", result.data);
          this.selectedPlan = {};
        }
        const refresh = firebase.functions.httpsCallable("refreshCustomer");
        refresh({
          id: this.billing.id,
          business_id: this.user.business_id,
          billing_id: this.user.business_id,
        });
        btnspinner.classList.toggle("hidden");
      });
    },
    async addSubscription() {
      var btnspinner = document.querySelector("#add-subscription .spinner");
      btnspinner.classList.toggle("hidden");
      // const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));
      // delay(6000).then(() => {
      //   btnspinner.classList.toggle("hidden");
      // });
      await firebase.db
        .collection("businesses")
        .doc(this.user.business_id)
        .collection("billing")
        .doc(this.user.business_id)
        .update({
          _update_subscription: this.plans[0].price_id,
        })
        .then(() => {
          btnspinner.classList.toggle("hidden");
          this.loadingPlan = true;
        });
    },
    async createStripe() {
      // create stripe element for credit card
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK);
      this.elements = await this.stripe.elements();
      var style = {
        base: {
          fontSize: "16px",
        },
      };
      var card = this.elements.create("card", { style: style });
      card.mount("#card-element");
      card.on("change", (event) => {
        let errorElement = document.getElementById("card-element-errors");
        if (event.error) {
          errorElement.textContent = event.error.message;
        } else {
          errorElement.textContent = " ";
        }
      });
      var form = document.getElementById("creditcard-form");
      var btnspinner = document.querySelector("#update-credit-card .spinner");
      form.addEventListener("submit", (event) => {
        event.preventDefault();
        btnspinner.classList.toggle("hidden");
        this.submittedCard = true;
        this.loadingCard = true;
        this.stripe
          .createToken(card, { name: this.creditcard.name })
          .then(async (result) => {
            btnspinner.classList.toggle("hidden");
            this.ccFormExpanded = false;
            if (result.error) {
              // console.log("error:", result.error.message);
            } else {
              card.clear();
              // write the token.id to the database and it triggers the update to stripe
              await firebase.db
                .collection("businesses")
                .doc(this.user.business_id)
                .collection("billing")
                .doc(this.user.business_id)
                .update({
                  _update_token: result.token.id,
                })
                .then(() => {
                  this.submittedCard = false;
                });
            }
          });
      });
    },
    async deleteCreditCard() {
      this.ccFormExpanded = false;
      this.submittedCard = true;
      this.loadingCard = true;
      const deleteCC = firebase.functions.httpsCallable("deleteDefaultSource");
      deleteCC({
        doc_id: this.user.business_id,
        id: this.billing.id,
        default_source: this.billing.default_source,
      }).then((result) => {
        if (result.data.deleted) {
          this.creditcard.name = "";
          this.creditcard.last4 = "";
        }
        this.submittedCard = false;
      });
    },
    async updateBillingInfo() {
      var btnspinner = document.querySelector("#update-billing-info .spinner");
      btnspinner.classList.toggle("hidden");
      await firebase.db
        .collection("businesses")
        .doc(this.user.business_id)
        .collection("billing")
        .doc(this.user.business_id)
        .update({
          _update_billing: true,
          email: this.billing.email,
          name: this.billing.name,
          address: {
            line1: this.billing.address.line1,
            line2: this.billing.address.line2,
            city: this.billing.address.city,
            country: this.billing.address.country,
            state: this.billing.address.state,
            postal_code: this.billing.address.postal_code,
          },
        })
        .then(() => {
          btnspinner.classList.toggle("hidden");
        });
    },
    getUser() {
      if (firebase.auth.currentUser.uid) {
        firebase.db
          .collection("users")
          .doc(firebase.auth.currentUser.uid)
          .onSnapshot((doc) => {
            this.user = {
              uid: doc.id,
              ...doc.data(),
            };
            // console.info("user", this.user);
            this.getBusinessInfo();
          });
      }
    },
    async getBusinessInfo() {
      await firebase.db
        .collection("businesses")
        .doc(this.user.business_id)
        .get()
        .then((doc) => {
          this.business = {
            id: doc.id,
            ...doc.data(),
          };
        });
      if (this.business.business_type != "stripe") {
        this.$router.push("/dashboard");
      }
      // console.info("business", this.business);
      this.getBillingInfo();
    },
    async getBillingInfo() {
      await firebase.db
        .collection("businesses")
        .doc(this.user.business_id)
        .collection("billing")
        .doc(this.user.business_id)
        .onSnapshot(async (doc) => {
          if (!doc.exists) {
            // create billing
            await firebase.db
              .collection("businesses")
              .doc(this.user.business_id)
              .collection("billing")
              .doc(this.user.business_id)
              .set({
                name: this.business.business_name,
                business_code: this.business.business_code,
              });
          } else {
            let billing = doc.data();
            // console.log("billing snapshot", billing);
            if (!("address" in billing) || billing.address == null) {
              billing.address = {
                city: "",
                country: "",
                line1: "",
                line2: "",
                postal_code: "",
                state: "",
              };
            }
            if (!("city" in billing.address)) {
              billing.address.city = "";
            }
            if (!("country" in billing.address)) {
              billing.address.country = "";
            }
            if (!("line1" in billing.address)) {
              billing.address.line1 = "";
            }
            if (!("line2" in billing.address)) {
              billing.address.line2 = "";
            }
            if (!("postal_code" in billing.address)) {
              billing.address.postal_code = "";
            }
            if (!("state" in billing.address)) {
              billing.address.state = "";
            }
            if (!("subscriptions" in billing)) {
              billing.subscriptions = { data: [] };
            }
            if (!("data" in billing.subscriptions)) {
              billing.subscriptions.data = [];
            }
            this.billing = {
              id: doc.id,
              ...billing,
            };
          }
          this.loadingBilling = false;
          // console.info("billing", this.billing);
          if (!this.submittedCard) {
            if (this.billing.default_source) {
              this.creditcard = this.billing.sources.data.find(
                ({ id }) => id === this.billing.default_source
              );
              this.ccFormExpanded = false;
              // console.log("creditcard", this.creditcard);
            } else {
              this.creditcard = {
                last4: "",
              };
              this.ccFormExpanded = true;
            }
            this.loadingCard = false;
          }
          this.getStripeInfo();
        });
    },
    async getStripeInfo() {
      if (this.plans.length) {
        // plans have been retrieved, no need to contact stripe, skip to getCurrentPlan
        this.getCurrentPlan();
        this.loadingPlan = false;
      } else {
        // get product and plan info from stripe
        const getProductsFromStripe = firebase.functions.httpsCallable(
          "stripeProducts"
        );
        getProductsFromStripe().then((result) => {
          const products = result.data;
          // console.log("products", products);
          const getPlansFromStripe = firebase.functions.httpsCallable(
            "stripePlans"
          );
          getPlansFromStripe().then((result) => {
            const plans = result.data;
            // console.log("plans", plans);
            plans.data.forEach((plan) => {
              const product = products.data.find(
                ({ id }) => id === plan.product
              );
              const data = {
                active: plan.active,
                price_id: plan.id,
                product_id: plan.product,
                name: product.name,
                description: product.description,
                price: plan.amount / 100,
                price_string: (plan.amount / 100).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }),
              };
              this.plans.push(data);
            });
            this.getCurrentPlan();
            this.loadingPlan = false;
            // console.log("this.plans", this.plans);
          });
        });
      }
    },
    async getCurrentPlan() {
      if (this.billing.subscriptions.data.length) {
        // current plan
        const current = this.billing.subscriptions.data[0];
        const plan = this.plans.find(
          ({ product_id }) => product_id === current.plan.product
        );
        this.selectedPlan = {
          ...plan,
          cancel_at: current.cancel_at,
          cancel_at_period_end: current.cancel_at_period_end,
          canceled_at: current.canceled_at,
          collection_method: current.collection_method,
          current_period_start: current.current_period_start,
          current_period_end: current.current_period_end,
          id: current.id,
          status: current.status,
        };
      } else {
        // no selected plan
        this.selectedPlan = {};
      }
    },
    convertDate(unix_timestamp) {
      const date = new Date(unix_timestamp * 1000);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
    },
  },
};
</script>

<style scoped>
.refresh {
  animation: spin-around 1s infinite reverse linear;
}
.spinner {
  height: 25px;
  width: 25px;
  background-color: transparent;
  border-top: 5px solid white;
  border-right: 5px solid white;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  border-radius: 50%;
  animation: spin-around 1s infinite linear;
}
.spinner.dark {
  border-top: 5px solid #111827;
  border-right: 5px solid #111827;
}
@keyframes spin-around {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#delete-subscription,
#update-subscription {
  min-width: 300px;
}
#add-subscription {
  min-width: 275px;
}
#update-credit-card {
  min-width: 290px;
}
#update-billing-info {
  min-width: 170px;
}
.slideup {
  transform: scaleY(0);
  height: 0;
}
#creditcard-form label,
#billing-address label {
  min-width: 135px;
}
</style>
