import { render } from "./BillingAccount.vue?vue&type=template&id=246836ec"
import script from "./BillingAccount.vue?vue&type=script&lang=js"
export * from "./BillingAccount.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "246836ec"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('246836ec', script)) {
    api.reload('246836ec', script)
  }
  
  module.hot.accept("./BillingAccount.vue?vue&type=template&id=246836ec", () => {
    api.rerender('246836ec', render)
  })

}

script.__file = "src/views/BillingAccount.vue"

export default script