<template>
  <BillingAccountComponent />
</template>

<script>
import BillingAccountComponent from '../components/BillingAccountComponent.vue'

export default {
  name: 'BillingAccount',
  components: {
    BillingAccountComponent,
  },
}
</script>
